import { loadStripe } from "@stripe/stripe-js";
import React from "react";

function getStripeKeys(mode = "test") {
  const stripeKeys = {
    live: {
      publicKey:
        "pk_live_51OgQ2eIaRGXT8H1iZkOjdId4788j03v61WwWVCmhL14dF2Tmomo5wkOknwFa2ckeyjPfBonIO1H7Y9n0sp6iCzFM0045qlMdGy",
      secretKey:
        "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",
    },
    test: {
      publicKey:
        "pk_test_51OgQ2eIaRGXT8H1iTC4jQRUtEcjVzpT353k9V1D1YEncRGnaDNahEVUqfkzLjvmO0QTJYSwX6WprtCCDdRv2A7vA00Uyds5CWX",
      secretKey:
        "sk_test_51OgQ2eIaRGXT8H1iCj3cnLt3FMGqK7gY2nQovrO7QsL2WAdDxChVvjqyt9U0tK1bHxAyuUPci6f8lNScfdfuUA7200IWAjoIKu",
    },
  };

  return stripeKeys[mode];
}

const { publicKey, secretKey } = getStripeKeys("live");

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(publicKey);

const BavaStripeContext = React.createContext();

async function generateClientSecret(amount) {
  const result = await fetch(
    `https://api.stripe.com/v1/payment_intents?amount=${amount}&currency=sek&automatic_payment_methods[enabled]=true`,
    {
      method: "POST",
      headers: {
        "Content-type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + secretKey,
      },
    }
  );

  const { client_secret } = await result.json();

  return client_secret;
}

function useBavaStripe() {
  return React.useContext(BavaStripeContext);
}

function BavaStipeProvider({ invoice, children }) {
  const [isLoading, setLoading] = React.useState(true);
  const [paymentIntent, setPaymentIntent] = React.useState(null);

  const clientSecret = React.useMemo(() => {
    return invoice.clientSecret;
  }, [invoice]);

  const isPaid = React.useMemo(() => {
    switch (paymentIntent?.status) {
      case "succeeded":
        return true;
      default:
        return false;
    }
  }, [paymentIntent]);

  React.useEffect(() => {
    if (clientSecret) {
      const loadPaymentIntent = async () => {
        const stripe = await stripePromise;
        const { paymentIntent } = await stripe.retrievePaymentIntent(
          clientSecret
        );
        setPaymentIntent(paymentIntent);
        setLoading(false);
      };

      loadPaymentIntent();
    } else {
      setLoading(false);
    }
  }, [clientSecret]);

  return (
    <BavaStripeContext.Provider
      value={{
        invoice,
        isPaid,
        isLoading,
        stripe: stripePromise,
        generateClientSecret,
      }}
    >
      {children}
    </BavaStripeContext.Provider>
  );
}

export { BavaStipeProvider, useBavaStripe };

import { createClient } from "@supabase/supabase-js";
import React from "react";
import { useParams } from "react-router-dom";

// Create a single supabase client for interacting with your database
const supabase = createClient(
  "https://gwrlzvqhempoqfjlkjau.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imd3cmx6dnFoZW1wb3FmamxramF1Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDg3NDMyNDIsImV4cCI6MjAyNDMxOTI0Mn0.boVDcSL0m4oG5K6suP15F9u3XwgBC5apkXtyScqKM9g"
);

const SupabaseContext = React.createContext();

function useBavaSupabase() {
  return React.useContext(SupabaseContext);
}

function BavaSupabaseProvider({ children }) {
  const [isLoading, setLoading] = React.useState(true);
  const [invoice, setInvoice] = React.useState(null);
  const { id } = useParams();

  const updateClientSecret = async (clientSecret) => {
    if (!invoice) {
      return;
    }

    const update = await supabase
      .from("bava_invoices")
      .update({
        ...invoice,
        clientSecret,
      })
      .eq("id", invoice.id);

    return update;
  };

  React.useEffect(() => {
    supabase
      .from("bava_invoices")
      .select()
      .eq("invoice_id", id)
      .then(({ data }) => {
        setInvoice(data ? data[0] : null);
        setLoading(false);
      });
  }, [id]);

  return (
    <SupabaseContext.Provider
      value={{ isLoading, invoice, setInvoice, updateClientSecret }}
    >
      {children}
    </SupabaseContext.Provider>
  );
}

export { BavaSupabaseProvider, useBavaSupabase };

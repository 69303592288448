import { BavaSupabaseProvider, useBavaSupabase } from "../Helpers/BavaSupabase";
import { BavaStipeProvider, useBavaStripe } from "../Helpers/BavaSripe";
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import React from "react";
import currency from "currency.js";

const SEK = new Intl.NumberFormat("sv-SE", {
  style: "currency",
  currency: "SEK",
  useGrouping: true,
});

function InvoiceLoading() {
  return (
    <div className="bava__content bava__loading">
      <div className="bava__center">
        <div className="bava__icon" style={{ opacity: 0.4 }}>
          <i className="fa-solid fa-spinner fa-spin"></i>
        </div>
        <div className="bava__info">Laddar faktura</div>
      </div>
    </div>
  );
}

function InvoiceIsPaid() {
  return (
    <div className="bava__content bava__is__paid">
      <div className="bava__center">
        <div className="bava__icon" style={{ color: "#29d654" }}>
          <i className="fa-solid fa-circle-check"></i>
        </div>
        <div className="bava__info">Betalat</div>
      </div>
    </div>
  );
}

function InvoiceNotFound() {
  return (
    <div className="bava__content bava__no__invoice">
      {" "}
      <div className="bava__center">
        <div className="bava__icon" style={{ color: "#de3a3a" }}>
          <i className="fa-solid fa-circle-xmark"></i>
        </div>
        <div className="bava__info">Faktura kunde inte hittas</div>
      </div>
    </div>
  );
}

function calculateFees(amount) {
  return currency((amount / 100) * 0.04 + 4.5).intValue;
}

function calculateTotal(amount) {
  return calculateFees(amount) + amount;
}

function InvoiceDetails() {
  const { invoice } = useBavaStripe();
  return (
    <div className="bava__invoice__details">
      <div className="invoice__title">Betala din faktura</div>
      <div className="invoice__id">Faktura ID: {invoice.invoice_id}</div>
      <div className="invoice__row">
        <div className="invoice__label">Belopp:</div>
        <div className="invoice__value">{SEK.format(invoice.amount / 100)}</div>
      </div>
      <div className="invoice__row">
        <div className="invoice__label">Avgift:</div>
        <div className="invoice__value">
          {SEK.format(calculateFees(invoice.amount) / 100)}
        </div>
      </div>

      <div className="invoice__row invoice__total">
        <div className="invoice__label">Totalt att betala:</div>
        <div className="invoice__value">
          {SEK.format(calculateTotal(invoice.amount) / 100)}
        </div>
      </div>
    </div>
  );
}

function InvoiceContent() {
  const bavaStripe = useBavaStripe();

  return bavaStripe.isLoading ? (
    <InvoiceLoading />
  ) : bavaStripe.isPaid ? (
    <InvoiceIsPaid />
  ) : (
    <div className="bava__content">
      <InvoiceDetails />
      <Elements
        stripe={bavaStripe.stripe}
        options={{
          mode: "payment",
          currency: "sek",
          amount: calculateTotal(bavaStripe.invoice.amount),
          paymentMethodCreation: "manual",
          appearance: {},
        }}
      >
        <InvoicePayment />
      </Elements>
    </div>
  );
}

function InvoicePayment() {
  const [paymentElemenetLoaded, setPaymentElementLoaded] =
    React.useState(false);

  const bavaStripe = useBavaStripe();
  const bavaSupabase = useBavaSupabase();

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const { error: submitError } = await elements.submit();

    if (submitError) {
      console.log(submitError.message);
      return;
    }

    const clientSecret =
      bavaStripe.invoice?.clientSecret ||
      (await bavaStripe.generateClientSecret(
        calculateTotal(bavaStripe.invoice.amount)
      ));

    if (!bavaStripe.invoice?.clientSecret) {
      bavaSupabase.updateClientSecret(clientSecret);
    }

    const { error } = await stripe.confirmPayment({
      elements,
      clientSecret,
      confirmParams: {
        return_url: "https://pay.bava.one/" + bavaStripe.invoice.invoice_id,
      },
    });

    if (error) {
      alert(error.message);
      return;
    }
  };

  return (
    <div className="bava__payment">
      <form autoComplete="off" onSubmit={handleSubmit}>
        <PaymentElement
          onReady={() => setPaymentElementLoaded(true)}
          options={{
            business: {
              name: "Bava",
            },
            layout: {
              type: "tabs",
              defaultCollapsed: false,
              spacedAccordionItems: true,
              radios: false,
            },
          }}
        />
        {paymentElemenetLoaded && (
          <button type="submit">
            Betala {SEK.format(calculateTotal(bavaStripe.invoice.amount) / 100)}
          </button>
        )}
      </form>
    </div>
  );
}

function InvoiceContainer() {
  const bavaSupabase = useBavaSupabase();

  return (
    <div className="bava__container">
      {bavaSupabase.isLoading ? (
        <InvoiceLoading />
      ) : !bavaSupabase.invoice ? (
        <InvoiceNotFound />
      ) : (
        <BavaStipeProvider invoice={bavaSupabase.invoice}>
          <InvoiceContent />
        </BavaStipeProvider>
      )}
    </div>
  );
}

function InvoicePage() {
  return (
    <div className="bava__page">
      <BavaSupabaseProvider>
        <InvoiceContainer />
      </BavaSupabaseProvider>
    </div>
  );
}

export default InvoicePage;
